import { checkedLabelRules } from "./DrawerRule";
import {
  AnchorType,
  BoxType,
  FormatBodyResultType,
  LabelDataType,
  PolygonBoxType,
  Shape,
  ShapeElement,
  ShapeType,
} from "./DrawerTypes";

export const convertType = (type: string) => {
  switch (type) {
    case "BBox":
      return "rect";
    case "rect":
      return "BBox";
    case "polygon":
      return "Polygon";

    case "point":
      return "Keypoint";

    case "Keypoint":
      return "rect";

    default:
      return type;
  }
};

export const convertBBoxAnchor = (box: BoxType): AnchorType[] => {
  return [
    { index: "0", x: box.topLeftX, y: box.topLeftY },
    { index: "1", x: box.bottomRightX, y: box.bottomRightY },
  ];
};

export const convertPolygonAnchor = (
  box: { x: number; y: number }[]
): AnchorType[] => {
  return box.map((reg, index) => {
    return {
      index: index.toString(),
      x: reg.x,
      y: reg.y,
    };
  });
};

const convertToPointLabel = (resource: ShapeElement[]): Shape[] => {
  const pointLabels = resource.map((item) => {
    return item.region.map((reg, index) => {
      if (reg.hasOwnProperty("topLeftX")) {
        return {
          id: item.id.toString(),
          type: convertType(item.type),
          classificationId: item.classificationId,
          isComplete: true,
          visible: true,
          anchors: convertBBoxAnchor(reg as unknown as BoxType),
        } as Shape;
      } else {
        return {
          id: item.id.toString() + "-" + index,
          type: "point",
          classificationId: reg.classificationId,
          isComplete: true,
          visible: true,
          anchors: [{ index: "0", x: reg.x, y: reg.y }],
          binding: item.id.toString(),
        } as Shape;
      }
    });
  });
  return pointLabels.flat();
};

const convertToPolygonLabel = (resource: ShapeElement[]): Shape[] => {
  return resource.map((item) => {
    //const type = convertType(item.type);
    return {
      id: item.id.toString(),
      type: "polygon",
      classificationId: item.classificationId,
      anchors: convertPolygonAnchor(item.region as unknown as PolygonBoxType[]),
      isComplete: true,
      visible: true,
    };
  });
};
export const convertToRectLabel = (resource: ShapeElement[]): Shape[] => {
  return resource.map((item) => {
    const type = convertType(item.type);
    return {
      id: item.id.toString(),
      type: type,
      classificationId: item.classificationId,
      anchors: convertBBoxAnchor(item.region as unknown as BoxType),
      isComplete: true,
      visible: true,
    };
  });
};

export const convertFromApiMiddleware = (
  datasetType: LabelDataType,
  resource: ShapeElement[]
): Shape[] => {
  if (datasetType === "KEYPOINT") {
    return convertToPointLabel(resource);
  }
  if (datasetType === "SEGMENTATION") {
    return convertToPolygonLabel(resource);
  }
  if (datasetType === "") {
  }
  return [];
};

export const convertRectRegion = (anchors: AnchorType[]) => {
  const top = anchors[0];
  const bottom = anchors[1];
  return {
    bottomRightX: bottom.x,
    bottomRightY: bottom.y,
    topLeftX: top.x,
    topLeftY: top.y,
  } as BoxType;
};

export const convertPolygonRegion = (anchors: AnchorType[]) => {
  return anchors.map(({ x, y }) => {
    return {
      x,
      y,
    };
  });
};

export const convertPointRegion = (anchors: AnchorType[]) => {
  const center = anchors[0];
  return {
    x: center.x,
    y: center.y,
  };
};

const convertRegion = (type: string, anchors: AnchorType[]) => {
  if (type === "rect") {
    convertRectRegion(anchors);
  } else if (type === "polygon") {
    return convertPolygonRegion(anchors);
  } else if (type === "point") {
    return convertPointRegion(anchors);
  } else {
    return [];
  }
};
//crop

export const findRectSide = (anchors: AnchorType[]) => {
  const pointX = anchors.map((anchor) => anchor.x); //取出所有x值
  const pointY = anchors.map((anchor) => anchor.y); //取出所有y值
  const minX = Math.min(...pointX);
  const maxX = Math.max(...pointX);
  const minY = Math.min(...pointY);
  const maxY = Math.max(...pointY);
  return {
    left: minX,
    right: maxX,
    top: minY,
    bottom: maxY,
  };
};

export const cropPolygon = (
  imageElement: HTMLImageElement,
  anchors: AnchorType[]
): string => {
  const img = imageElement;
  const { naturalWidth: nw, naturalHeight: nh } = img;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) return "";
  const { left, right, top, bottom } = findRectSide(anchors);
  const leftX = left * nw;
  const topY = top * nh;
  const width = (right - left) * nw;
  const height = (bottom - top) * nh;
  canvas.width = nw;
  canvas.height = nh;
  ctx.clearRect(0, 0, nw, nh);
  ctx.save();
  ctx.beginPath();
  anchors.forEach(({ x, y }, index) => {
    if (index === 0) {
      ctx.moveTo(x * nw, y * nh);
    } else {
      ctx.lineTo(x * nw, y * nh);
    }
  });
  ctx.closePath();
  ctx.clip();
  // * !重要說明:圖像繪製從0,0開始，drawImage(原始圖像,-x,-y)，將圖像向左偏移對齊矩形的最小邊，這樣drawImage才會從矩形圍的區域開始畫!重要說明
  ctx.drawImage(img, -left, -top, nw, nh);
  ctx.restore();
  const croppedImage = ctx.getImageData(leftX, topY, width, height);
  ctx.clearRect(0, 0, nw, nh);
  const offsetX = canvas.width / 2 - width / 2;
  const offsetY = canvas.height / 2 - height / 2;
  ctx.putImageData(croppedImage, offsetX, offsetY);
  return canvas.toDataURL();
};

export const cropRect = (
  imageElement: HTMLImageElement,
  anchors: AnchorType[]
): string => {
  const img = imageElement;
  const { naturalWidth: nw, naturalHeight: nh } = img;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) return "";
  const { left, right, top, bottom } = findRectSide(anchors);
  const leftX = left * nw;
  const topY = top * nh;
  const width = (right - left) * nw;
  const height = (bottom - top) * nh;
  canvas.width = nw;
  canvas.height = nh;
  ctx.clearRect(0, 0, nw, nh);
  ctx.save();
  ctx.beginPath();
  ctx.rect(leftX, topY, width, height);
  ctx.closePath();
  ctx.clip();
  // * !重要說明:圖像繪製從0,0開始，drawImage(原始圖像,-x,-y)，將圖像向左偏移對齊矩形的最小邊，這樣drawImage才會從矩形圍的區域開始畫!重要說明
  ctx.drawImage(img, -left, -top, nw, nh);
  ctx.restore();
  const croppedImage = ctx.getImageData(leftX, topY, width, height);
  ctx.clearRect(0, 0, nw, nh);
  const offsetX = canvas.width / 2 - width / 2;
  const offsetY = canvas.height / 2 - height / 2;
  ctx.putImageData(croppedImage, offsetX, offsetY);
  return canvas.toDataURL();
};

export const cropCircle = (
  imageElement: HTMLImageElement,
  anchors: AnchorType[]
): string => {
  const img = imageElement;
  const { naturalWidth: nw, naturalHeight: nh } = img;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) return "";
  const center = anchors[0];
  const radius = anchors[1];
  const centerX = center.x * nw;
  const centerY = center.y * nh;
  const radiusX = radius.x * nw;
  const radiusY = radius.y * nh;
  canvas.width = nw;
  canvas.height = nh;
  ctx.clearRect(0, 0, nw, nh);
  ctx.save();
  ctx.beginPath();
  ctx.arc(centerX, centerY, radiusX, 0, Math.PI * 2);
  ctx.closePath();
  ctx.clip();
  ctx.drawImage(img, -center.x - radius.x, -center.y - radius.y, nw, nh);
  ctx.restore();
  const croppedImage = ctx.getImageData(
    centerX - radiusX,
    centerY - radiusY,
    radiusX * 2,
    radiusY * 2
  );
  ctx.clearRect(0, 0, nw, nh);
  const offsetX = canvas.width / 2 - radiusX;
  const offsetY = canvas.height / 2 - radiusY;
  ctx.putImageData(croppedImage, offsetX, offsetY);
  return canvas.toDataURL();
};

export const destinationCrop = (
  imageElement: HTMLImageElement,
  anchors: AnchorType[]
): string => {
  const img = imageElement;
  const { naturalWidth: nw, naturalHeight: nh } = img;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) return "";
  const { left, right, top, bottom } = findRectSide(anchors);
  const leftX = left * nw;
  const topY = top * nh;
  const width = (right - left) * nw;
  const height = (bottom - top) * nh;
  canvas.width = nw;
  canvas.height = nh;
  //ctx.clearRect(0, 0, nw, nh);
  ctx.save();
  // ctx.globalCompositeOperation = 'destination-out';
  ctx.drawImage(img, 0, 0, nw, nh);
  ctx.clearRect(leftX, topY, width, height);

  // ctx.globalCompositeOperation = 'source-over';
  //ctx.drawImage(img, 0, 0, nw, nh);
  return canvas.toDataURL();
};

export const fetchAllowType = (
  datasetType: string
  // classificationsAmount: number
): ShapeType[] => {
  if (datasetType === "KEYPOINT") {
    return ["point", "rect"];
  }
  if (datasetType === "SEGMENTATION") {
    return ["polygon"];
  }
  return ["rect", "circle", "polygon", "point"];
};

export const fetchArrangeTypeRule = (datasetType: string) => {
  if (datasetType === "KEYPOINT") {
    return [
      checkedLabelRules.checkPointInRect,
      checkedLabelRules.checkAllPointInRect,
      checkedLabelRules.checkPointClass,
      checkedLabelRules.checkRectWithPointCount,
    ];
  } else {
    return [];
  }
};

export const matchPointRectId = (
  anchor: { x: number; y: number },
  shapes: Shape[]
): string | undefined => {
  const { x, y } = anchor;
  const rects = shapes.filter((shape) => shape.type === "rect");

  for (const rect of rects) {
    const { left, right, top, bottom } = findRectSide(rect.anchors);
    if (x > left && x < right && y > top && y < bottom) {
      return rect.id;
    }
  }
  return undefined;
};

export const hexToRgba = (color: string) => {
  const hex = color.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, 0.5)`;
};

export const createPolygonLabels = (shapes: Shape[]) => {
  const polygons = shapes.filter((shape) => shape.type === "polygon");
  return polygons.map((rect, index) => {
    return {
      id: index,
      type: "Polygon",
      classificationId: rect.classificationId,
      isImport: false,
      region: convertRegion(rect.type, rect.anchors),
      source: "Manual",
    };
  });
};

export const createRectLabel = (shapes: Shape[]) => {
  const rects = shapes.filter((shape) => shape.type === "rect");
  return rects.map((rect, index) => {
    return {
      id: index,
    };
  });
};

export const formatBody = (
  datasetType: LabelDataType,
  shapes: Shape[]
): FormatBodyResultType => {
  //convert body middleware ==> keypoint
  if (datasetType === "KEYPOINT") {
    const rects = shapes.filter((shape) => shape.type === "rect");
    const points = shapes.filter((shape) => shape.type === "point");
    const body = rects.map((rect) => {
      const rectId = rect.id;
      const pointByRects = points.filter((shape) => shape.binding === rectId);

      return {
        id: rect.id,
        type: "Keypoint",
        classificationId: null,
        isImport: false,
        region: [
          {
            ...convertRectRegion(rect.anchors),
            classificationId: null,
          },
          ...pointByRects.map((item) => {
            return {
              ...convertPointRegion(item.anchors),
              classificationId: item.classificationId,
            };
          }),
        ],
      };
    });

    if (body.some((item) => !item)) {
      return {
        formatting: false,
        data: null,
        message: "",
      };
    } else {
      return {
        formatting: true,
        data: body,
        message: "",
      };
    }
  } else if (datasetType === "SEGMENTATION") {
    return {
      formatting: true,
      data: createPolygonLabels(shapes),
      message: "",
    };
  } else {
    return {
      formatting: false,
      data: null,
      message: "body error",
    };
  }
};
