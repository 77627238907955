import React, { forwardRef, useImperativeHandle, useRef } from "react";
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";

export interface ZoomComponentRef {
  reset: () => void;
}

interface ZoomComponentProps {
  disabled: boolean;
  children: React.ReactNode;
}

export interface ZoomComponentHandle {
  reset: () => void;
}
const ZoomComponent = forwardRef<ZoomComponentHandle, ZoomComponentProps>(
  (props, ref) => {
    const { disabled, children } = props;
    const wrapperRef = useRef<ReactZoomPanPinchRef | null>(null);

    useImperativeHandle(ref, () => ({
      reset: () => {
        if (wrapperRef.current) {
          wrapperRef.current.resetTransform();
        }
      },
    }));

    return (
      <TransformWrapper
        ref={wrapperRef}
        initialScale={1}
        wheel={{ disabled: false }}
        pinch={{ disabled: false }}
        maxScale={5}
        disabled={disabled}
      >
        {() => (
          <React.Fragment>
            <TransformComponent>{children}</TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    );
  }
);

export default ZoomComponent;
