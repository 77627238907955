import { KonvaEventObject } from "konva/lib/Node";
import { AnchorType, Shape } from "../../contexts/DrawerTypes";
import EditorCircle from "./EditorCircle";
import EditorPoint from "./EditorPoint";
import EditorPolygon from "./EditorPolygon";
import EditorRect from "./EditorRect";

const EditorMiddleware = ({
  shape,
  rectColor,
  anchors,
  radius,
  fill,
  handleUpdateEditShape,
  handleContext,
}: {
  shape: Shape;
  rectColor: string;
  anchors: AnchorType[];
  fill: boolean;
  radius: number;
  handleUpdateEditShape: (anchors: AnchorType[]) => void;
  handleContext?: (e: KonvaEventObject<PointerEvent>) => void;
}) => {
  const props = { shape, anchors, rectColor, fill };
  switch (shape.type) {
    case "circle":
      return (
        <EditorCircle
          {...props}
          handleUpdateEditShape={handleUpdateEditShape}
          handleContext={handleContext}
        />
      );
    case "bbox":
    case "rect":
      return (
        <EditorRect
          {...props}
          handleUpdateEditShape={handleUpdateEditShape}
          handleContext={handleContext}
        />
      );
    case "polygon":
      return (
        <EditorPolygon
          {...props}
          handleUpdateEditShape={handleUpdateEditShape}
          handleContext={handleContext}
        />
      );
    case "point":
      return (
        <EditorPoint
          {...props}
          radius={radius}
          handleUpdateEditShape={handleUpdateEditShape}
          handleContext={handleContext}
        />
      );
    default:
      return null;
  }
};

export default EditorMiddleware;
