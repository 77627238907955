import { KonvaEventObject } from "konva/lib/Node";
import { Box } from "konva/lib/shapes/Transformer";
import { Stage } from "konva/lib/Stage";
import { Vector2d } from "konva/lib/types";
import { useEffect, useRef, useState } from "react";
import { Group, Rect, Transformer } from "react-konva";
import { AnchorType, Shape } from "../../contexts/DrawerTypes";
import { hexToRgba } from "../../contexts/DrawerUtils";

function getCorner(
  pivotX: number,
  pivotY: number,
  diffX: number,
  diffY: number,
  angle: number
) {
  const a = angle + Math.atan2(diffY, diffX);
  const distance = Math.sqrt(diffX * diffX + diffY * diffY);
  /// find angle from pivot to corner
  //angle += Math.atan2(diffY, diffX);
  /// get new x and y and round it off to integer
  const x = pivotX + distance * Math.cos(a);
  const y = pivotY + distance * Math.sin(a);

  return { x: x, y: y };
}

function getClientRect(rotatedBox: Box) {
  const { x, y, width, height } = rotatedBox;
  const rad = rotatedBox.rotation;

  const p1 = getCorner(x, y, 0, 0, rad);
  const p2 = getCorner(x, y, width, 0, rad);
  const p3 = getCorner(x, y, width, height, rad);
  const p4 = getCorner(x, y, 0, height, rad);

  const minX = Math.min(p1.x, p2.x, p3.x, p4.x);
  const minY = Math.min(p1.y, p2.y, p3.y, p4.y);
  const maxX = Math.max(p1.x, p2.x, p3.x, p4.x);
  const maxY = Math.max(p1.y, p2.y, p3.y, p4.y);

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
}

const findRectSide = (anchors: AnchorType[]) => {
  //console.log(points)
  const pointX = anchors.map((anchor) => anchor.x); //取出所有x值
  const pointY = anchors.map((anchor) => anchor.y); //取出所有y值
  const minX = Math.min(...pointX);
  const maxX = Math.max(...pointX);
  const minY = Math.min(...pointY);
  const maxY = Math.max(...pointY);
  return {
    left: minX,
    right: maxX,
    top: minY,
    bottom: maxY,
  };
};

interface BoxType {
  x: number;
  y: number;
  width: number;
  height: number;
}

const convertBox = (anchors: AnchorType[]): BoxType => {
  const top = anchors[0];
  const bottom = anchors.length > 1 ? anchors[1] : anchors[0];
  return {
    x: top.x,
    y: top.y,
    width: bottom.x - top.x,
    height: bottom.y - top.y,
  };
};

const RectEditor = ({
  shape,
  anchors,
  rectColor,
  fill,
  handleUpdateEditShape,

  handleContext,
}: {
  shape: Shape;
  anchors: AnchorType[];
  rectColor: string;
  fill: boolean;
  handleUpdateEditShape: (anchors: AnchorType[]) => void;

  handleContext?: (e: KonvaEventObject<PointerEvent>) => void;
}) => {
  const { id, isComplete } = shape;
  const [stage, setStage] = useState<Stage | null>(null);
  const [boxPoint, setBoxPoint] = useState<BoxType>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const rectRef = useRef<any>();
  const trRef = useRef<any>();

  const handleGroupMouseOut = (e: KonvaEventObject<PointerEvent>) => {
    e.target.getStage()!.container().style.cursor = "default";
  };
  const handleAnchorContext = (e: KonvaEventObject<PointerEvent>) => {
    e.evt.preventDefault();
    return handleContext && handleContext(e);
  };
  const handleGroupDragEnd = (e: KonvaEventObject<DragEvent>) => {
    if (e.target.getAttrs().id === id) {
      const updatedAnchors = [...anchors].map((anchor) => {
        return {
          ...anchor,
          x: anchor.x + e.target.x(),
          y: anchor.y + e.target.y(),
        };
      });
      const box = convertBox(updatedAnchors);
      setBoxPoint(box);
      handleUpdateEditShape(updatedAnchors);
      e.target.position({ x: 0, y: 0 });
    }
  };
  const handleGroupMouseOver = (e: KonvaEventObject<PointerEvent>) => {
    e.target.getStage()!.container().style.cursor = "move";
    if (e.target.getStage()) {
      setStage(e.target.getStage());
    }
  };
  const groupDragBound = (pos: Vector2d) => {
    let { x, y } = pos;
    if (stage) {
      const sw = stage.width();
      const sh = stage.height();
      const { left, right, top, bottom } = findRectSide(anchors);
      if (top + y < 0) y = -1 * top;
      if (left + x < 0) x = -1 * left;
      if (bottom + y > sh) y = sh - bottom;
      if (right + x > sw) x = sw - right;
      return { x, y };
    } else {
      return { x, y };
    }
  };

  useEffect(() => {
    const box = convertBox(anchors);
    setBoxPoint(box);
  }, [id]);

  useEffect(() => {
    if (trRef && trRef.current) {
      trRef.current.nodes([rectRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [shape]);

  return (
    <Group
      id={id}
      draggable={isComplete}
      onMouseOut={handleGroupMouseOut}
      dragBoundFunc={groupDragBound}
      onContextMenu={handleAnchorContext}
      onDragEnd={handleGroupDragEnd}
    >
      <Rect
        visible={true}
        id={id}
        onMouseOver={handleGroupMouseOver}
        ref={rectRef}
        x={boxPoint.x}
        y={boxPoint.y}
        strokeWidth={2}
        width={boxPoint.width}
        height={boxPoint.height}
        fill={fill ? hexToRgba(rectColor) : "transparent"}
      />
      <Transformer
        ref={trRef}
        borderStroke={rectColor}
        borderStrokeWidth={1}
        anchorFill={rectColor}
        anchorStroke={"#fff"}
        anchorStrokeWidth={2}
        anchorCornerRadius={6}
        anchorSize={6}
        flipEnabled={false}
        rotateEnabled={false}
        onTransformEnd={() => {
          const node = rectRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          node.scaleX(1);
          node.scaleY(1);
          const box = {
            x: node.x(),
            y: node.y(),
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          };
          const newAnchors = [
            { index: "0", x: box.x, y: box.y },
            { index: "1", x: box.x + box.width, y: box.y + box.height },
          ];
          const newBox = convertBox(newAnchors);
          setBoxPoint(newBox);
          handleUpdateEditShape(newAnchors);
        }}
        boundBoxFunc={(oldBox, newBox) => {
          const box = getClientRect(newBox);
          if (stage) {
            const isOut =
              box.x < 0 ||
              box.y < 0 ||
              box.x + box.width > stage.width() ||
              box.y + box.height > stage.height();
            if (isOut) {
              return oldBox;
            }
            return newBox;
          }
          return oldBox;
        }}
      />
    </Group>
  );
};

export default RectEditor;
