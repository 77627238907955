import { KonvaEventObject } from "konva/lib/Node";
import { useMemo } from "react";
import { Circle, Group, Rect } from "react-konva";
import { AnchorType, Shape } from "../../contexts/DrawerTypes";
import { hexToRgba } from "../../contexts/DrawerUtils";

const PainterRect = ({
  shape,
  points,
  rectColor,
  fill,
  onSelected,
  onContext,
}: {
  shape: Shape;
  points: AnchorType[];
  rectColor: string;
  fill: boolean;
  onSelected: () => void;
  onCompleted?: () => void;
  onContext?: (e: KonvaEventObject<PointerEvent>) => void;
}) => {
  const { id, isComplete, visible } = shape;

  const handleAnchorContext = (e: KonvaEventObject<PointerEvent>) => {
    e.evt.preventDefault();
    return onContext && onContext(e);
  };

  const boxPoint = useMemo(() => {
    const top = points[0];
    const bottom = points.length > 1 ? points[1] : points[0];
    return {
      x: top.x,
      y: top.y,
      width: bottom.x - top.x,
      height: bottom.y - top.y,
    };
  }, [points]);

  return (
    <Group visible={visible}>
      <Rect
        id={id}
        onClick={onSelected}
        onContextMenu={handleAnchorContext}
        x={boxPoint.x}
        y={boxPoint.y}
        width={boxPoint.width}
        height={boxPoint.height}
        stroke={rectColor}
        fill={fill ? hexToRgba(rectColor) : "transparent"}
        strokeWidth={3}
      />
      {!isComplete &&
        points.map((point, index) => (
          <Circle
            onContextMenu={handleAnchorContext}
            key={id + index * 3}
            id={point.index.toString()}
            x={point.x}
            y={point.y}
            stroke="#eee"
            radius={5}
            fill={rectColor}
          />
        ))}
    </Group>
  );
};

export default PainterRect;
