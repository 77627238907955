import React, { useLayoutEffect, useState } from "react";
import { ConditionType } from "../TestResult-Virtual-Display/index";

export interface ResultType {
  bbox: number[];
  class: string;
  score: number;
  masks?: number[];
  max_distance?: number;
  perimeter?: number;
  area?: number;
  center?: number[];
  keypoint?: number[][];
  angle?: number;
  distance?: number;
}

interface VirtualImageCompProps {
  url: string;
  results: ResultType[] | null;
  width: number;
  condition: ConditionType | null;
}

const VirtualImageComp: React.FC<VirtualImageCompProps> = ({
  url,
  results,
  width,
  condition,
}) => {
  const fixedToNumber = (value: number) => {
    const n = 2;
    return Math.floor(value * Math.pow(10, n)) / Math.pow(10, n);
  };

  const { color, showBox, showClass, showScore, scoreRange, distanceRange } =
    condition!;
  const [originSize, setOriginSize] = useState<{ w: number; h: number }>({
    w: 0,
    h: 0,
  });
  const [imageSize, setImageSize] = useState<[number, number]>([0, 0]);
  const [resultItems, setResultItems] = useState<ResultType[]>([]);

  const divSetting = (item: ResultType, key: number) => {
    //console.log(item);
    const isKeypoint = Boolean(item.keypoint);

    const [tx, ty, bx, by] = item.bbox;
    const [cx, cy] = item?.center || [-1, -1];
    const [rw, rh] = imageSize;
    const { w, h } = originSize;
    //計算出百分比
    const topX = tx / w,
      topY = ty / h;
    const bottomX = bx / w,
      bottomY = by / h;

    const topCX = (cx - 15) / w,
      topCY = (cy - 15) / h;

    let circleShow = true;
    if (cx < 0 || cy < 0) {
      circleShow = false;
    }

    const divStyle = {
      color: color,
      fontSize: "0.5em",
      position: "absolute",
      border: `1px solid ${color}`,
      top: topY * rh + "px",
      left: topX * rw + "px",
      width: (bottomX - topX) * rw + "px",
      height: (bottomY - topY) * rh + "px",
    } as React.CSSProperties;

    return (
      <>
        {/*             {circleShow && <div style={divCircleStyle} key={`c${key}`}></div>}
         */}{" "}
        <div style={divStyle} key={key}>
          {item.angle ? (
            <p style={{ marginTop: -20 }}>
              distance:{item.distance?.toFixed(2)}
            </p>
          ) : null}
          <p
            style={{
              paddingTop: (bottomY - topY) * rh + "px",
              whiteSpace: "nowrap",
            }}
          >
            {showClass ? item.class : ""}
            {showScore ? `[${fixedToNumber(item.score)}]` : ""}
            {circleShow ? ` (${item?.angle?.toFixed(2)}°)` : ""}
            {/* {`${showClass ? item.class+' ' : null}${showScore ? ':' + item.score : null}`} */}
          </p>
        </div>
        {isKeypoint &&
          item.keypoint?.map((point, index) => (
            <div
              key={index}
              style={{
                position: "absolute",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                transform: " translate(-50%, -50%)",
                top: (point[1] * rh) / h,
                left: (point[0] * rw) / w,
                background: color,
              }}
            ></div>
          ))}
      </>
    );
  };

  useLayoutEffect(() => {
    if (!results) {
      return setResultItems([]);
    }
    const filterScore = results!.filter(
      (item) => item.score > scoreRange[0] && item.score < scoreRange[1]
    );
    const filterResult = distanceRange
      ? filterScore.filter(
          (item) =>
            item.distance! > distanceRange[0] &&
            item.distance! < distanceRange[1]
        )
      : filterScore;
    setResultItems([...filterResult]);
  }, [url, condition]);

  //console.log(condition?.distanceRange);

  return (
    <div style={{ position: "relative" }}>
      <img
        src={url}
        alt="#"
        width={width}
        onLoad={(e) => {
          const { naturalWidth, naturalHeight } = e.target as HTMLImageElement;
          setOriginSize({ w: naturalWidth, h: naturalHeight });
          setImageSize([width, (width * naturalHeight) / naturalWidth]);
        }}
      />
      {showBox && resultItems
        ? resultItems.map((item: ResultType, key: number) =>
            divSetting(item, key)
          )
        : null}
    </div>
  );
};

export default VirtualImageComp;
