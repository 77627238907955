import { KonvaEventObject } from "konva/lib/Node";
import { Stage } from "konva/lib/Stage";
import { Vector2d } from "konva/lib/types";
import { useEffect, useRef, useState } from "react";
import { Circle, Group, Transformer } from "react-konva";
import { AnchorType, Shape } from "../../contexts/DrawerTypes";
import { hexToRgba } from "../../contexts/DrawerUtils";

interface CircleType {
  x: number;
  y: number;
  radius: number;
}

const findCircleRectSide = (anchors: AnchorType[]) => {
  const { x: centerX, y: centerY } = anchors[0];
  const { x: radiusX, y: radiusY } = anchors[1];
  return {
    left: centerX - radiusX,
    right: centerX + radiusX,
    top: centerY - radiusY,
    bottom: centerY + radiusY,
    //radius: radiusX>radiusY?radiusX:radiusY
  };
};

const convertCircle = (anchors: AnchorType[]): CircleType => {
  const top = anchors[0];
  const bottom = anchors.length > 1 ? anchors[1] : anchors[0];
  return {
    x: top.x,
    y: top.y,
    radius: bottom.x,
  };
};

const CircleEditor = ({
  shape,
  anchors,
  rectColor,
  fill,
  handleUpdateEditShape,
  handleContext,
}: {
  shape: Shape;
  anchors: AnchorType[];
  rectColor: string;
  fill: boolean;
  handleUpdateEditShape: (anchors: AnchorType[]) => void;
  handleContext?: (e: KonvaEventObject<PointerEvent>) => void;
}) => {
  const { id, isComplete } = shape;
  const [stage, setStage] = useState<Stage | null>(null);
  const [circlePoint, setCirclePoint] = useState<CircleType>({
    x: 0,
    y: 0,
    radius: 0,
  });
  const circleRef = useRef<any>();
  const trRef = useRef<any>();

  const handleGroupMouseOut = (e: KonvaEventObject<PointerEvent>) => {
    e.target.getStage()!.container().style.cursor = "default";
  };

  const handleAnchorContext = (e: KonvaEventObject<PointerEvent>) => {
    e.evt.preventDefault();
    return handleContext && handleContext(e);
  };

  const handleGroupMouseOver = (e: KonvaEventObject<PointerEvent>) => {
    e.target.getStage()!.container().style.cursor = "move";
    if (e.target.getStage()) {
      setStage(e.target.getStage());
    }
  };

  const groupDragBound = (pos: Vector2d) => {
    let { x, y } = pos;
    if (stage) {
      const sw = stage.width();
      const sh = stage.height();
      const { left, right, top, bottom } = findCircleRectSide(anchors);
      const rx = (right - left) / 2;
      const ry = (bottom - top) / 2;
      if (y - ry < 0) y = ry;
      if (x - rx < 0) x = rx;
      if (y + ry > sh) y = sh - ry;
      if (x + rx > sw) x = sw - rx;
      return { x, y };
    } else {
      return { x, y };
    }
  };

  // const handleGroupDrag = (e: KonvaEventObject<DragEvent>) => {
  //     const lastPos = e.target._lastPos;
  //     if (lastPos && circleRef.current) {
  //         console.log('drag', e.target)
  //         console.log('dragEnd', circleRef.current)
  //         const { radius } = circleRef.current.getAttrs();
  //         const { x, y } = lastPos;
  //         const newAnchors = [
  //             { index: '0', x: x + radius, y: y + radius },
  //             { index: "1", x: radius, y: radius }
  //         ]
  //         const circle = convertCircle(newAnchors);
  //         setCirclePoint(circle);
  //         handleUpdateEditShape(newAnchors)
  //         e.target.position({ x: 0, y: 0 });
  //     };
  // };

  useEffect(() => {
    const circle = convertCircle(anchors);
    setCirclePoint(circle);
  }, [id]);

  useEffect(() => {
    if (trRef && trRef.current) {
      trRef.current.nodes([circleRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, []);

  return (
    <Group
      id={id}
      draggable={isComplete}
      onMouseOut={handleGroupMouseOut}
      //  onDragEnd={handleGroupDrag}
      onContextMenu={handleAnchorContext}
    >
      <Circle
        id={id}
        onMouseOver={handleGroupMouseOver}
        dragBoundFunc={groupDragBound}
        ref={circleRef}
        x={circlePoint.x}
        y={circlePoint.y}
        radius={circlePoint.radius}
        draggable={true}
        strokeWidth={3}
        stroke={rectColor}
        fill={fill ? hexToRgba(rectColor) : "transparent"}
        onDragEnd={(e) => {
          const { x, y, radius } = e.target.getAttrs();
          const newAnchors = [
            { index: "0", x: x, y: y },
            { index: "1", x: radius, y: radius },
          ];
          const circle = convertCircle(newAnchors);
          setCirclePoint(circle);
          handleUpdateEditShape(newAnchors);
        }}
        onTransformEnd={() => {
          const node = circleRef.current;
          const r = node.radius() * node.scaleX();
          node.scaleX(1);
          node.scaleY(1);
          const box = {
            x: node.x(),
            y: node.y(),
            r: Math.max(5, r),
          };
          const newAnchors = [
            { index: "0", x: box.x, y: box.y },
            { index: "1", x: r, y: r },
          ];
          const circle = convertCircle(newAnchors);
          setCirclePoint(circle);
          handleUpdateEditShape(newAnchors);
        }}
      />
      <Transformer
        ref={trRef}
        enabledAnchors={[
          "top-left",
          "top-right",
          "bottom-left",
          "bottom-right",
        ]}
        borderStroke={rectColor}
        borderStrokeWidth={3}
        anchorFill={rectColor}
        anchorStroke={"#fff"}
        anchorStrokeWidth={2}
        anchorCornerRadius={10}
        anchorSize={10}
        flipEnabled={false}
        rotateEnabled={false}
        boundBoxFunc={(oldBox, newBox) => {
          // limit resize
          if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
            return oldBox;
          }
          return newBox;
        }}
      />
    </Group>
  );
};

export default CircleEditor;
