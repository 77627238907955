import { ExceptionOutlined, LoadingOutlined } from "@ant-design/icons";

import { Button, Drawer, message, Popover } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactZoomPanPinchRef } from "react-zoom-pan-pinch";
import { useCanvas } from "../../contexts/DrawerContext";
import { fetchShapeDatasetApi } from "../../contexts/DrawerService";
import { Shape, ShapeImageJson, ShapeType } from "../../contexts/DrawerTypes";
import { convertFromApiMiddleware } from "../../contexts/DrawerUtils";
import { offlineUrlExpress } from "../../urls";
import Controller from "./Controller";
import Information from "./Information";
import ShapeCanvas from "./ShapeCanvas";
import ShapeList from "./ShapeList";
import TopPanel from "./TopPanel";

export const colorType = {
  green: "#19ab31",
  blue: "#386afa",
  yellow: "#f39c12",
};

export interface DataOptionType {
  filterLabeled: boolean | undefined;
  classed: string | undefined;
  isImport: boolean | undefined;
  filterSource: string;
}

export interface CanvasOptionType {
  locked: boolean;
  onDraw: boolean;
  drawing: boolean;
  rectColor: string;
  defaultClassification: number | null;
  shapeType: ShapeType | null;
  fill: boolean;
  brightness: number;
  radius: number;
  totalIndex: number;
}

const initDataOption: DataOptionType = {
  filterLabeled: undefined,
  classed: undefined,
  isImport: undefined,
  filterSource: "All",
};

const initCanvasOption: CanvasOptionType = {
  locked: false,
  onDraw: false,
  drawing: false,
  rectColor: colorType.green,
  defaultClassification: null,
  shapeType: null,
  fill: false,
  brightness: 0,
  radius: 10,
  totalIndex: 0,
};

const CanvasDrawer = () => {
  const {
    canvasState,
    setClearHistory,
    clearSelectShape,
    onCloseCanvas,
    fetchClassSummaryAsync,
    setRecordHistory,
    setCurrentImage,
    setRefetch,
  } = useCanvas();
  const { datasetType } = canvasState;

  const [dataOption, setDataOption] = useState<DataOptionType>(initDataOption);
  const [canvasOption, setCanvasOption] =
    useState<CanvasOptionType>(initCanvasOption);
  const [loadingImage, setLoadingImage] = useState(false);

  const wrapperRef = useRef<ReactZoomPanPinchRef | null>(null);

  const handleScale = (value: number) => {
    wrapperRef.current?.setTransform(0, 0, value);
  };

  const fetchCurrentImageAsync = async (index: number) => {
    try {
      const { datasetId } = canvasState;
      const { filterLabeled, classed, isImport, filterSource } = dataOption;
      const res = await fetchShapeDatasetApi(
        datasetId,
        index,
        filterLabeled,
        classed,
        isImport,
        filterSource
      );
      if (res.data && res.data.length > 0) {
        const imageData = res.data[0] as ShapeImageJson;
        const labels = imageData.datasetDetectLabels;
        const anchorLabels: Shape[] = convertFromApiMiddleware(
          datasetType,
          labels
        );
        handleCanvasOption("originData", JSON.stringify(anchorLabels));
        setRecordHistory(anchorLabels);
        setCurrentImage(imageData, anchorLabels);
        setRefetch(false);
      } else {
        throw new Error("No result");
      }
    } catch (err: any) {
      message.error(err.message);
    }
  };

  const handleCanvasOption = (
    key: string,
    value: boolean | string | number
  ) => {
    const updatedOption = { ...canvasOption, [key]: value };
    setCanvasOption(updatedOption);
  };

  const handleDataOption = (option: DataOptionType) => {
    setDataOption(option);
  };

  const onDrawShape = (type: ShapeType | null) => {
    if (canvasOption.shapeType === type) {
      setCanvasOption({
        ...canvasOption,
        shapeType: null,
        onDraw: false,
      });
    } else {
      clearSelectShape();
      setCanvasOption({
        ...canvasOption,
        shapeType: type,
        onDraw: true,
      });
    }
  };

  const handleClose = () => {
    setDataOption(initDataOption);
    setCanvasOption(initCanvasOption);
    onCloseCanvas();
  };

  const fetchingAll = useCallback(async () => {
    try {
      setLoadingImage(true);
      fetchCurrentImageAsync(canvasState.currentIndex);
      fetchClassSummaryAsync(canvasState.datasetId);
      setClearHistory();
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingImage(false);
    }
  }, [canvasState.currentIndex, canvasState.datasetId, dataOption]);

  useEffect(() => {
    if (canvasState.datasetId && canvasState.modalOpen) {
      fetchingAll();
    }
  }, [canvasState.datasetId, canvasState.modalOpen, canvasState.currentIndex]);

  useEffect(() => {
    if (canvasState.refetch) {
      fetchCurrentImageAsync(canvasState.currentIndex);
      fetchClassSummaryAsync(canvasState.datasetId);
    }
  }, [canvasState.refetch]);

  useEffect(() => {
    if (canvasState.selectShape) {
      setCanvasOption({ ...canvasOption, onDraw: false, shapeType: null });
    }
  }, [canvasState.selectShape]);

  return (
    <Drawer
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: 10,
            justifyContent: "space-between",
          }}
        >
          <h3>Image Labeling - {datasetType}</h3>
          <Popover
            content={<Information state={canvasState} />}
            title="Data Information"
            trigger="click"
            placement="topLeft"
          >
            <Button icon={<ExceptionOutlined />}>Information</Button>
          </Popover>
        </div>
      }
      width={"100%"}
      open={canvasState.modalOpen}
      onClose={handleClose}
    >
      <div
        style={{
          display: canvasState.modalOpen ? "flex" : "none",
          gap: 20,
          justifyContent: "center",
          height: "80vh",
        }}
      >
        <div id="controller">
          <Controller
            dataOption={dataOption}
            canvasOption={canvasOption}
            onSetCanvasOption={handleCanvasOption}
            onSetDataOption={handleDataOption}
            onDrawShape={onDrawShape}
          />
        </div>
        <div
          id="draw-canvas"
          style={{
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: 10,
            width: 1020,
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          }}
        >
          <div style={{ paddingTop: 10, width: "100%" }}>
            <TopPanel
              canvasOption={canvasOption}
              onScale={handleScale}
              onSetCanvasOption={handleCanvasOption}
              resetScale={() => wrapperRef.current?.resetTransform()}
            />
          </div>

          <div style={{ position: "relative" }}>
            {loadingImage && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  position: "absolute",
                  zIndex: 99,
                  background: "rgba(10,10,10,0.4)",
                }}
              >
                <LoadingOutlined style={{ fontSize: 56, color: "white" }} />
              </div>
            )}

            {canvasState.currentImage && (
              <ShapeCanvas
                imageUrl={`${offlineUrlExpress}/api/get-image?url=${canvasState.currentImage?.url}`}
                option={canvasOption}
                onSetCanvasOption={handleCanvasOption}
              />
            )}
          </div>
        </div>

        <div>
          <ShapeList labelColor={canvasOption.rectColor} />
        </div>
      </div>
    </Drawer>
  );
};

export default CanvasDrawer;
